<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <div class="modal custom-fields show" id="new-user-modal" style="display: block">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="">
                    <div class="modal-content" v-on-click-away="closeModal">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("Pending candidates") }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="content-area__body" style="padding: 0; margin-top: 10px;">
                                <div class="data-table user-table" v-if="!isEmpty">
                                    <div class="table-wrap">
                                        <div class="table table-modal">
                                            <div class="table__row table__head">
                                                <div class="table-cell">{{ $t(`Name`) }}</div>
                                                <div class="table-cell">{{ $t(`Email`) }}</div>
                                                <div class="table-cell">{{ $t(`Updated on`) }}</div>
                                                <div class="table-cell" style="width: 110px" v-if="canDeleteCandidate"><span class="d-flex justify-content-end">{{ $t(`Actions`) }}</span></div>
                                            </div>
                                            <div class="table__row" v-for="candidate in candidates">
                                                <div class="table-cell">{{ candidate.name }}</div>
                                                <div class="table-cell"><span class="text-break">{{ candidate.email }}</span></div>
                                                <div class="table-cell" style="font-size:.75rem;"><span class="text-break">{{ candidate.last_update }}</span></div>
                                                <div class="table-cell" style="width: 110px"  v-if="canDeleteCandidate">
                                                    <div class="d-flex justify-content-end">
                                                        <a href="javascript:void(0)" class="control-button control-button--warning has_bg_color" @click="reInvite(candidate)">
                                                            <div class="control-button__icon">
                                                                <i class="eicon e-send"></i>
                                                            </div>
                                                        </a>
                                                        <a href="javascript:void(0)" class="control-button control-button--danger has_bg_color" @click=" deleteCandidate(candidate)">
                                                            <div class="control-button__icon">
                                                                <i class="eicon e-delete"></i>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 v-else style="color: #ff5f74;" class="mt-2">{{ $t('No candidates found.') }}</h6>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import client from "../../../app/api/Client";
import {canDeleteCandidate} from "../../../config/permission";

export default {
    props: ['jobId'],
    data() {
        return {
            candidates: [],
            isLoading: false
        }
    },
    computed: {
        isEmpty() {
            return this.candidates.length === 0;
        },
        canDeleteCandidate() {
            return canDeleteCandidate();
        }
    },
    methods: {
        reInvite(candidate) {
            let candidates = [candidate.id];
            this.inviteCandidates(candidates);
        },
        closeModal() {
            this.$emit('closeModal');
        },
        selectAll() {
            this.selected = this.allSelected ? Object.keys(this.permissions) : [];
        },
        async getPendingApplicants(l) {
            this.candidates = [];
            try {
                let {data: {data}} = await client().get(`/job/${this.jobId}/candidate/pending`)
                this.candidates = data;
            } catch (error) {
                this.$toast.error(error.response.data.message);
                if (error.response.status === 480 || error.response.status === 490) {
                    await this.$router.push({name: 'dashboard'});
                }
            }
        },
        inviteCandidates(candidates) {
            this.isLoading = true;
            client().post(`job/${this.jobId}/candidate/pending/invite`, {candidates: candidates}).then(({data: {data, message}}) => {
                this.$toast.success(message);
                this.getPendingApplicants();
            }).catch(({response: {data}}) => {
                this.$toast.error(data.message);
            })
        },
        deleteCandidate(candidate) {
          let candidates  = [candidate.id];
          this.deleteApplications(candidates);
        },
        deleteApplications(candidates) {
            client().post(`job/${this.jobId}/candidate/delete`, {candidates:candidates}).then(({data: {data, message}}) => {
                this.$toast.success(message);
                this.getPendingApplicants();
            });
        },
        // isValid() {
        //     this.errors.email = "";
        //
        //     if (this.form.email.length < 1) {
        //         this.errors.email = this.$t("Email field is required");
        //         return false;
        //     }
        //
        //     if (!_helper.validateEmail(this.form.email)) {
        //         this.errors.email = this.$t("Please type a valid email address");
        //         return false;
        //     }
        //     return true;
        // }
    },
    mounted() {
        this.getPendingApplicants();
    }
};
</script>

<style scoped>
.table-modal .table-cell {
    padding: 10px !important;
    background: #f8f8fd !important;
}
</style>
